import Parse from '@kickbox/common-util/src/parse';
import { KICKBOX_ROLES } from '@kickbox/common-util';

export default async (to, from, next) => {
  try {
    const user = await new Parse.Query(Parse.Object.extend(Parse.Role))
      .equalTo('name', KICKBOX_ROLES.BLUELION_ADMIN)
      .equalTo('users', Parse.User.current())
      .first();
    if (user) {
      return next();
    }
  } catch (error) {
    // User isn't authenticated
  }
  // Catching the error or not found:
  Parse.User.logOut();
  return next('/login');
};
