var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{attrs:{"dark":"","color":"teal"}},[_c('counter',{attrs:{"count":_vm.companies.length,"subtitle":"Companies","icon":"domain"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{attrs:{"dark":"","color":"teal"}},[_c('counter',{attrs:{"count":_vm.userCount,"subtitle":"Active users","icon":"person"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{attrs:{"dark":"","color":"teal"}},[_c('counter',{attrs:{"count":_vm.offerCount,"subtitle":"Published offers","icon":"local_offer"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{attrs:{"dark":"","color":"teal"}},[_c('counter',{attrs:{"count":_vm.projectCount,"subtitle":"Active projects","icon":"assignment"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"select-container"},[_c('v-autocomplete',{attrs:{"items":_vm.companies,"label":"Company"},on:{"change":_vm.getCompanyData}})],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"color":"white"}},[_c('p',{staticClass:"card-title"},[_vm._v(" Breakdown of project phases by company ")]),_c('horizontal-bar-chart',{staticClass:"height small-padding",attrs:{"chart-data":_vm.phaseData,"options":{
            responsive: true,
            maintainAspectRatio: false,
            scales: { xAxes: [{ stacked: true }], yAxes: [{ stacked: true }] }
          }}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"color":"white"}},[_c('p',{staticClass:"card-title"},[_vm._v(" Breakdown of offer statuses by company ")]),_c('horizontal-bar-chart',{staticClass:"height small-padding",attrs:{"chart-data":_vm.offerData,"options":{
            responsive: true,
            maintainAspectRatio: false,
            scales: { xAxes: [{ stacked: true }], yAxes: [{ stacked: true }] }
          }}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{attrs:{"color":"white"}},[_c('p',{staticClass:"card-title"},[_vm._v(" Projects created by month ")]),_c('line-chart',{staticClass:"height big-padding",attrs:{"chart-data":_vm.projectData,"options":{
            responsive: true,
            maintainAspectRatio: false,
          }}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"color":"white"}},[_c('p',{staticClass:"card-title"},[_vm._v(" Breakdown of user languages ")]),_c('dougnut-chart',{staticClass:"height big-padding",attrs:{"chart-data":_vm.languageData,"options":{ responsive: true, maintainAspectRatio: false }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }