<template>
  <div class="counter-item">
    <div class="count">
      {{ animatedCount }}
      <v-icon v-if="icon">
        {{ icon }}
      </v-icon>
    </div>
    <div class="count-subtitle">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'Counter',
  props: ['count', 'subtitle', 'icon'],
  data() {
    return {
      animatedCount: 0,
      lastTimestamp: 0,
    };
  },
  watch: {
    count(newCount, oldCount) {
      return this.animateCounter(oldCount, newCount, 300);
    }
  },
  methods: {
    animateCounter(start, end, duration, timestamp) {
      if (!timestamp) {
        this.animatedCount = start;
      }

      // Only update after a minimum of 20 miliseconds
      if (timestamp && (!this.lastTimestamp || timestamp - this.lastTimestamp >= 20)) {
        this.lastTimestamp = timestamp;
        const range = end - start;
        const steps = Math.abs(Math.floor(duration / 20));
        const increment = Math.abs(Math.ceil(range / steps));
        const desired = end - this.animatedCount;
        this.animatedCount += this.animatedCount > end ? desired : increment;
      }

      if (this.animatedCount !== end) {
        window.requestAnimationFrame((ts) => this.animateCounter(start, end, duration, ts));
      }
    },
  }
};
</script>

<style scoped>
  .counter-item {
    padding: 15px;
  }

  .count-subtitle {
    width: max-content;
    margin: 0 auto;
  }

  .count {
    width: max-content;
    margin: 0 auto;
    font-size: 35px;
  }
</style>
