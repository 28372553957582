<template>
  <v-container>
    <v-card class="pb-1">
      <v-tabs
        v-model="activeTabIndex"
        class="tabs-container"
        background-color="primary"
        dark
      >
        <v-tab
          v-for="lang in languages"
          :key="lang"
          :ruid="`tab_${lang}`"
          ripple
          @change="languageChanged(lang)"
        >
          {{ lang }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTabIndex">
        <v-tab-item
          v-for="lang in languages"
          :key="lang"
        >
          <slot />
        </v-tab-item>
      </v-tabs-items>
      <v-divider />
      <app-button
        right
        class="ml-5 my-3"
        @click="save"
      >
        Save
      </app-button>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['languages'],
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  computed: {
    ...mapGetters([
      'currentLanguage'
    ])
  },
  watch: {
    companyLanguage() {
      this.activeTabIndex = this.languages.indexOf(this.currentLanguage);
    }
  },
  created() {
    this.activeTabIndex = this.languages.indexOf(this.currentLanguage);
  },
  methods: {
    languageChanged(lang) {
      this.$store.commit('setCurrentLanguage', lang);
    },
    save() {
      this.$emit('save');
    }
  }
};
</script>
