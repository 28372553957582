<template>
  <div>
    <v-text-field
      v-model="data.url"
      clearable
      label="Id"
      :placeholder="placeholder"
    />
    <v-checkbox
      v-model="data.enabled"
      class="pt-0 mt-0"
      color="primary"
      hide-details
      label="Enabled"
    />
  </div>
</template>

<script>
export default {
  name: 'OnboardingRoomSettings',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit('input', data);
      }
    }
  }
};
</script>
