<template>
  <chapters
    :book-type="type"
  />
</template>

<script>
import { BLUE_BOOK } from '@kickbox/common-util/constants/book-names';
import Chapters from './Chapters';

export default {
  name: 'BlueBox',
  components: {
    Chapters
  },
  data() {
    return {
      type: BLUE_BOOK.key
    };
  }
};
</script>

<style scoped>

</style>
