<template>
  <chapters />
</template>

<script>
import Chapters from './Chapters';

export default {
  name: 'RedBox',
  components: {
    Chapters
  }
};
</script>

<style scoped>

</style>
