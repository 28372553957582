<template>
  <LanguagesBase
    v-if="languages.length"
    :languages="languages"
    @save="save"
  >
    <div class="px-5 pt-5">
      <p class="title">
        Kickbox Chapters
      </p>
      <Chapter
        v-for="(chapter) in chapters"
        :key="chapter.id"
        :chapter="chapter"
        @edit="goToEditChapter(chapter)"
      />
    </div>
  </LanguagesBase>
</template>

<script>
import { LanguageService } from '@kickbox/common-admin';
import { mapGetters } from 'vuex';
import { RED_BOOK } from '@kickbox/common-util/constants/book-names';
import bookService from '@/services/bookService';
import LanguagesBase from './LanguagesBase';
import Chapter from './Chapter';

export default {
  components: {
    LanguagesBase,
    Chapter
  },
  props: {
    bookType: {
      type: String,
      default: RED_BOOK.key
    }
  },
  computed: {
    ...mapGetters([
      'chapters',
      'currentLanguage',
      'languages'
    ])
  },
  async created() {
    this.$store.commit('setCurrentLanguage', 'EN');
    const allLanguages = await LanguageService.getLanguages();
    const languages = allLanguages.map((lang) => lang.key);
    this.$store.commit('setLanguages', languages);
    const allChapters = await bookService.getBookChapters(this.bookType);
    const chaptersPerLanguage = allChapters.map((chapter) => {
      const chapterWithLanguages = {
        id: chapter.id,
        chapterNumber: chapter.chapterNumber,
        content: {}
      };
      this.languages.forEach((language) => {
        const chapterLanguage = chapter.content[language];
        if (chapterLanguage) {
          chapterWithLanguages.content[language] = chapterLanguage;
        } else {
          chapterWithLanguages.content[language] = {
            name: '',
            tagline: ''
          };
        }
      });
      return chapterWithLanguages;
    });
    this.$store.commit('setChapters', chaptersPerLanguage);
  },
  methods: {
    async save() {
      if (this.invalidValues()) {
        this.$store.commit('showSnackBar', { text: 'At least 1 chapter name is missing.' });
      } else {
        await bookService.saveBookChapters(this.chapters, this.bookType);
        this.$store.commit('showSnackBar', { text: 'The chapters were saved.' });
      }
    },
    invalidValues() {
      return this.chapters.some((c) => !c.content[this.currentLanguage].name);
    },
    goToEditChapter(chapter) {
      this.$router.push({
        name: 'ExtraMilePages',
        params: {
          chapter,
          chapterNumber: chapter.chapterNumber,
          type: this.bookType
        }
      });
    }
  },
};
</script>

<style scoped>
  .title {
    margin-bottom: 1.5rem;
  }

  .information {
    margin-bottom: 1.5rem;
    color: gray;
  }
</style>
