<template>
  <v-app>
    <v-row
      justify="center"
      align="center"
      class="mt-12"
    >
      <div class="login-container pa-10">
        <h2 class="mb-6 font-weight-bold">
          Meta Login
        </h2>
        <v-text-field
          v-model="email"
          v-ruid="'email'"
          label="Email"
          type="email"
          required
          @keyup.enter="login"
        />
        <v-text-field
          v-model="password"
          v-ruid="'password'"
          :append-icon="showPass ? 'visibility_off' : 'visibility'"
          :type="showPass ? 'text' : 'password'"
          label="Password"
          required
          @click:append="() => (showPass = !showPass)"
          @keyup.enter="login"
        />
        <app-button
          v-ruid="'login'"
          :loading="loadingButton"
          block
          @click="login"
        >
          Login
        </app-button>
      </div>
    </v-row>
    <app-snack-bar />
  </v-app>
</template>

<script>
import { SnackBar } from '@kickbox/common-admin';
import UserService from '@/services/userService';

export default {
  components: {
    appSnackBar: SnackBar
  },
  data() {
    return {
      email: '',
      password: '',
      showPass: false,
      loadingButton: false,
    };
  },
  methods: {
    login() {
      if (!this.email || !this.password) {
        this.$store.dispatch('showSnackBar', { text: 'Please, fill in all the mandatory fields.' });
      } else {
        this.loadingButton = true;
        UserService.loginAdmin(this.email, this.password)
          .finally(() => {
            this.loadingButton = false;
          });
      }
    }
  }
};
</script>

<style scoped>
  label {
    color: rgba(0, 0, 0, .54);
    font-size: 12px;
  }
</style>
