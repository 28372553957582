const state = {
  user: true
};

const getters = {
  user: (st) => st.user
};

const mutations = {};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
