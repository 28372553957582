<template>
  <div>
    <v-text-field
      ref="ssoIdentifier"
      v-model="sso.serviceIdentifier"
      label="SSO Service"
      clearable
      placeholder="Enter the name of the sso identifier"
    />
    <v-checkbox
      v-model="sso.signed"
      hide-details
      label="Sign the SSO request"
      color="primary"
      class="mt-1"
    />
    <v-checkbox
      v-model="sso.usePost"
      hide-details
      label="Do a POST request"
      color="primary"
      class="mt-1"
    />
    <v-checkbox
      v-model="sso.passOnEmail"
      hide-details
      label="Pass the user's email to the company SSO"
      color="primary"
      class="mt-1"
    />
  </div>
</template>

<script>
export default {
  name: 'SsoSettings',
  props: {
    ssoData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      sso: null
    };
  },
  watch: {
    sso() {
      this.$emit('changed', this.sso);
    }
  },
  created() {
    this.sso = { ...this.ssoData };
  }
};
</script>
