import Parse from '@kickbox/common-util/src/parse';

export default {
  getEmails() {
    const query = new Parse.Query('Contact');
    query.descending('createdAt');
    query.limit(10000);
    return query.find();
  },
};
