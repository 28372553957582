import Parse from '@kickbox/common-util/src/parse';
import { LanguageService } from '@kickbox/common-admin';

export default {
  ...LanguageService,
  getAllKeys(language = 'EN') {
    return Parse.Cloud.run('getAllKeys', { language });
  },
  addLanguage(language) {
    return Parse.Cloud.run('addLanguage', { language });
  },
  createI18nCache(language) {
    return Parse.Cloud.run('createI18nCache', { language });
  },
  saveLanguage({ id, defaultLanguage }) {
    const languageInstance = new Parse.Object('Language', { id });
    languageInstance.save({ defaultLanguage });
  },
  async saveTranslation({ id, language, text }) {
    const I18nInstance = new (Parse.Object.extend('I18n'))();
    I18nInstance.id = id;
    await I18nInstance.save({ [language]: text });

    return this.createI18nCache(language);
  }
};
