<template>
  <v-container>
    <v-card class="content-card-padding">
      <content-template
        :items="contentArray"
        :default-array="defaultContent"
        @on-save-content="saveContent"
        @on-update-content="updateDefaultContent"
        @on-update-contents="updateDefaultContents"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import DEFAULT_CONTENT from '@kickbox/common-util/constants/default-content';
import { ContentTemplate, ContentService } from '@kickbox/common-admin';

export default {
  components: {
    ContentTemplate,
  },
  data() {
    return {
      itemsArray: DEFAULT_CONTENT.PAGES,
    };
  },
  computed: {
    ...mapGetters([
      'defaultContent'
    ]),
    contentArray() {
      return Object.values(this.itemsArray);
    },
    contentKeys() {
      return Object.values(this.contentArray).map((content) => content.key);
    }
  },
  created() {
    ContentService.getDefaultContent(this.contentKeys)
      .then((content) => {
        const updatedContent = content.map((contentItem) => ({ ...contentItem, isSaved: true }));
        this.$store.commit('setDefaultContent', updatedContent);
      });
  },
  methods: {
    ...mapMutations([
      'addDefaultContent',
      'deleteDefaultContent',
      'updateDefaultContent',
      'updateDefaultContents',
    ]),
    saveContent(contentToUpdate, contentArray) {
      contentToUpdate.forEach(async (content) => {
        const result = await ContentService.manageContent(content, contentArray);
        this.updateDefaultContent(result.content);
      });
      return this.$store.dispatch('showSnackBar', { text: 'The content has been updated.' });
    }
  }
};
</script>
