<template>
  <v-container>
    <base-company-list :trial="true" />
  </v-container>
</template>

<script>

import BaseCompanyList from '@/components/companies/BaseCompanyList';

export default {
  components: {
    BaseCompanyList
  }
};
</script>
