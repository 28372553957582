import Vue from 'vue';
import store from '@/store';

const state = {
  chapters: [],
  pages: [],
  currentLanguage: 'EN',
  languages: [],
};

const getContentForPage = (st, { pageNumber, language }) => {
  const page = st.pages.find((p) => p.pageNumber === pageNumber);
  const lang = language || st.currentLanguage;
  return page.content[lang];
};

const getters = {
  chapters: (st) => st.chapters,
  pages: (st) => (st.pages),
  page: (st) => (pageNumber) => st.pages.find((p) => p.pageNumber === pageNumber),
  currentLanguage: (st) => st.currentLanguage,
  languages: (st) => st.languages,
};

const mutations = {
  setChapters(st, payload) {
    st.chapters = payload;
  },
  setPages(st, payload) {
    st.pages = payload;
  },
  setCurrentLanguage(st, payload) {
    st.currentLanguage = payload;
  },
  setLanguages(st, payload) {
    st.languages = payload;
  },
  updateChapter(st, payload) {
    st.chapters = st.chapters.map((chapter) => {
      if (chapter.chapterNumber === payload.chapter.chapterNumber) {
        Vue.set(chapter.content, st.currentLanguage, payload.items);
      }
      return chapter;
    });
  },
  updatePage(st, payload) {
    const { pageNumber, page } = payload;
    st.pages = st.pages.map((p) => {
      if (p.pageNumber === pageNumber) {
        return {
          ...p,
          ...page,
          content: {
            ...p.content,
            ...page.content
          }
        };
      }
      return p;
    });
  },
  updatePageContent(st, payload) {
    const { pageNumber, language, content } = payload;

    const page = st.pages.find((p) => p.pageNumber === pageNumber);
    const lang = language || st.currentLanguage;
    store.commit('updatePage', {
      pageNumber,
      page: {
        ...page,
        content: {
          ...page.content,
          [lang]: content
        }
      }
    });
  },
  updatePageButton(st, payload) {
    const { index: buttonIndex, pageNumber, button, language } = payload;
    const content = getContentForPage(st, { pageNumber, language });

    content.buttons[buttonIndex] = {
      ...content.buttons[buttonIndex],
      ...button
    };

    store.commit('updatePageContent', {
      pageNumber,
      content
    });
  },
  addPageButton(st, payload) {
    const { pageNumber, title, link, language } = payload;
    const content = getContentForPage(st, { pageNumber, language });
    content.buttons = [...(content.buttons || []), { title, link }];

    store.commit('updatePageContent', {
      pageNumber,
      content
    });
  },
  updateHashTags(st, { item, pageNumber, language }) {
    const content = getContentForPage(st, { pageNumber, language });
    content.hashtags = item;

    store.commit('updatePageContent', {
      pageNumber,
      content
    });
  },
  removeHashTag(st, { item, pageNumber, language }) {
    const content = getContentForPage(st, { pageNumber, language });
    content.hashtags = content.hashtags.filter((_) => _ !== item);

    store.commit('updatePageContent', {
      pageNumber,
      content
    });
  },
  updateDescription(st, { item, pageNumber, language }) {
    const content = getContentForPage(st, { pageNumber, language });
    content.description = item;

    store.commit('updatePageContent', {
      pageNumber,
      content
    });
  }
};

const actions = {
  updateChapter({ commit }, payload) {
    commit('updateChapter', payload);
  },
  updatePageContent({ commit }, payload) {
    commit('updatePageContent', payload);
  },
  updatePageButton({ commit }, payload) {
    commit('updatePageButton', payload);
  },
  addPageButton({ commit }, payload) {
    commit('addPageButton', payload);
  },
  updateHashTags({ commit }, payload) {
    commit('updateHashTags', payload);
  },
  removeHashTag({ commit }, payload) {
    commit('removeHashTag', payload);
  },
  updateDescription({ commit }, payload) {
    commit('updateDescription', payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
