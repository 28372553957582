<template>
  <div class="inno-hub-content-details">
    <select-image
      ref="selectImage"
      :initial-image="cardImage"
      :width="340"
      :height="200"
      :label="`Card Image`"
      :file-size-limit="10485760"
      required
    />
    <v-text-field
      v-if="!newItem"
      :value="item.id"
      label="Id"
      :disabled="true"
      class="mt-4"
    />
    <v-text-field
      ref="title"
      v-model="title"
      label="Title *"
      :rules="item.type === 'service' ? [rules.required, rules.textExceededCharacters(title, 35)]
        : [rules.required]"
      :counter="item.type === 'service' ? 35 : null"
      clearable
      required
      class="mt-4"
    />
    <v-text-field
      v-model="summary"
      label="Summary"
      clearable
      required
      class="mt-4"
    />
    <v-autocomplete
      v-model="selectedTags"
      clearable
      :items="tags"
      item-text="title"
      chips
      small-chips
      deletable-chips
      return-object
      label="Tags"
      multiple
    />
    <label class="editor d-block mb-3">Description</label>
    <editor
      class="mt-1"
      :content="description"
      :show-submit-button="false"
      :required="true"
      @keyUp="description = $event"
    />
    <label class="editor d-block mb-3 mt-4">How it Works</label>
    <editor
      class="mt-1 how-it-works-editor"
      :content="howItWorks"
      :show-submit-button="false"
      :required="true"
      @keyUp="howItWorks = $event"
    />
    <v-text-field
      ref="price"
      v-model="price"
      label="Price *"
      :rules="item.type === 'service' ? [rules.required, rules.textExceededCharacters(price, 20)]
        : []"
      :counter="item.type === 'service' ? 20 : null"
      clearable
      required
      class="mt-4"
    />
    <v-text-field
      v-model="orderNow"
      label="Order Now"
      clearable
      required
      class="mt-4"
    />
    <v-text-field
      v-model="pdfUrl"
      label="PDF url"
      clearable
    />
    <select-users
      v-model="authors"
      :source="people"
      label="People"
    />
    <v-autocomplete
      v-model="relatedServices"
      clearable
      :items="allServices"
      item-value="id"
      item-text="title"
      return-object
      label="Related Services"
      multiple
    />
    <v-autocomplete
      v-model="relatedArticles"
      clearable
      :items="allBestPractices"
      item-value="id"
      item-text="title"
      return-object
      label="Related Articles"
      multiple
    />
    <v-checkbox
      v-model="disabled"
      label="Disable"
      hint="if it is enabled, the page will be shown in the Admin Panel for all the companies"
      persistent-hint
      class="mb-6"
    />
    <v-card-actions>
      <app-button
        v-if="newItem"
        secondary
        cancel
        class="mr-2"
        @click="$emit('cancel')"
      >
        Cancel
      </app-button>
      <app-button
        primary
        @click="save"
      >
        {{ primaryButtonLabel }}
      </app-button>
    </v-card-actions>
  </div>
</template>

<script>
import { Editor, ValidateMixin, SelectUsers, SelectImage } from '@kickbox/common-admin';
import { TAGS, PEOPLE } from '@kickbox/common-util/constants/inno-hub';
import { mapGetters } from 'vuex';

export default {
  name: 'ContentDetails',
  components: {
    Editor,
    SelectUsers,
    SelectImage
  },
  mixins: [ValidateMixin],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tags: TAGS,
      people: PEOPLE,
      cardImage: null,
      title: '',
      summary: '',
      selectedTags: [],
      description: '',
      howItWorks: '',
      price: '',
      orderNow: '',
      pdfUrl: '',
      authors: [],
      relatedServices: [],
      relatedArticles: [],
      disabled: false
    };
  },
  computed: {
    ...mapGetters(['services', 'bestPractices']),
    image() {
      return this.$refs.selectImage.image;
    },
    newItem() {
      return !this.item;
    },
    primaryButtonLabel() {
      return this.newItem ? 'Add' : 'Save';
    },
    allServices() {
      return this.newItem ? this.services : this.services.filter((s) => s.id !== this.item.id);
    },
    allBestPractices() {
      return this.newItem
        ? this.bestPractices : this.bestPractices.filter((a) => a.id !== this.item.id);
    },
    formMandatoryFields() {
      if (this.item.type === 'service') {
        return {
          title: this.title,
          price: this.price,
        };
      }
      return {
        title: this.title
      };
    },
    formFields() {
      const baseFields = {
        title: this.title,
        summary: this.summary,
        tags: this.selectedTags,
        text: this.description,
        howItWorks: this.howItWorks,
        price: this.price,
        downloadLink: this.orderNow,
        pdfUrl: this.pdfUrl,
        authors: this.authors,
        relatedServices: this.relatedServices,
        relatedArticles: this.relatedArticles,
        disabled: this.disabled
      };
      const editField = {
        id: this.item && this.item.id,
        type: this.item && this.item.type,
      };
      return this.newItem ? baseFields : { ...baseFields, ...editField };
    }
  },
  async created() {
    if (this.item) {
      this.cardImage = this.item.cardImage;
      this.title = this.item.title;
      this.summary = this.item.summary;
      this.selectedTags = this.item.tags;
      this.description = this.item.text;
      this.howItWorks = this.item.howItWorks;
      this.price = this.item.price;
      this.orderNow = this.item.downloadLink;
      this.pdfUrl = this.item.pdfUrl;
      this.authors = this.item.authors;
      this.relatedServices = this.item.relatedServices;
      this.relatedArticles = this.item.relatedArticles;
      this.disabled = this.item.disabled;
    }
  },
  methods: {
    validateMandatoryFields() {
      if (this.checkFields()) return true;

      this.$store.dispatch('showSnackBar', { text: 'Please fill all mandatory fields' });
      return false;
    },
    async save() {
      if (!this.image.hasImage()) {
        this.$store.dispatch('showSnackBar', { text: 'You need to add a card image' });
        return;
      }
      // Validate mandatory input field values
      if (!this.validateMandatoryFields()) return;

      const imageProperty = {
        file: this.image,
        oldUrl: this.cardImage,
        sameImage: this.image.currentIsInitial
      };
      this.$emit('save', {
        fields: this.formFields,
        imageProperty
      });
    }
  }
};
</script>

<style lang="scss">
.inno-hub-content-details {
  label.editor {
    color: rgba(0, 0, 0, .54);
    font-size: 14px;
  }
  .ql-editor {
    height: 300px !important;

    ol, ul {
      padding-left: 0.2rem;
    }

    li.ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 3em;
    }

    li {
      margin: 0.1rem 0;
    }
  }
  .how-it-works-editor .ql-editor{
    height: 200px !important;
  }
  a {
    text-decoration: none !important;
    color: #2b94fc !important;
  }
}
.v-list-item--disabled {
  background-color: #ebebeb;
  pointer-events: initial;
}

.v-input--checkbox .v-messages__message {
  font-size: 15px;
}

</style>
