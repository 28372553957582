<template>
  <div>
    <app-layout
      :items="items"
      :menu-names="menuNames"
    />
  </div>
</template>

<script>
import { Layout, UserService } from '@kickbox/common-admin';
import { TYPES } from '@kickbox/common-util/constants/inno-hub';

export default {
  components: {
    appLayout: Layout
  },
  data() {
    return {
      items: [
        {
          action: 'dashboard',
          menu: 'Dashboard',
          to: { name: 'Dashboard' }
        },
        {
          action: 'business',
          menu: 'Companies',
          items: [
            {
              value: 'Manage',
              to: { name: 'CompaniesManage' }
            },
            {
              value: 'Trials',
              to: { name: 'CompaniesTrialManage' }
            }
          ]
        },
        {
          action: 'email',
          menu: 'Landing Page',
          items: [
            {
              value: 'Email List',
              to: { name: 'EmailsList' }
            }
          ]
        },
        {
          action: 'assignment',
          menu: 'Default Content',
          items: [
            {
              value: 'Content',
              to: { name: 'Content' }
            },
            {
              value: 'Email coaching',
              to: { name: 'Emails' }
            },
            {
              action: 'local_offer',
              value: 'Tags',
              to: { name: 'TagList' }
            }
          ]
        },
        {
          action: 'book',
          menu: 'Extra Mile',
          items: [
            {
              value: 'Red Kickbook',
              to: { name: 'ExtraMileRedKickbook' }
            },
            {
              value: 'Blue Kickbook',
              to: { name: 'ExtraMileBlueKickbook' }
            }
          ]
        },
        {
          action: 'lightbulb',
          menu: 'Inno hub',
          items: [
            {
              value: 'Services',
              to: { name: 'InnoHubServices', params: { type: TYPES.SERVICE } }
            },
            {
              value: 'Best Practice',
              to: { name: 'InnoHubBestPractice', params: { type: TYPES.BEST_PRACTICE } }
            }
          ]
        },
        {
          action: 'language',
          menu: 'Languages',
          to: { name: 'Languages' }
        },
        {
          action: 'logout',
          menu: 'Logout',
          onClick() {
            UserService.logout();
          }
        }
      ],
      menuNames: {
        CompaniesManage: 'Manage Companies',
        CompaniesTrialManage: 'Manage Trial Companies',
        CreateCompany: 'Create Company',
        EditCompany: 'Edit Company',
        TagList: 'Manage Default Tags',
        Languages: 'Languages',
        ExtraMileRedKickbook: 'Red Kickbook',
        ExtraMileBlueKickbook: 'Blue Kickbook',
        ExtraMilePages: 'Pages',
        InnoHubServices: 'Services',
        InnoHubBestPractice: 'Best Practices',
        InnoHubAddContent: 'Add Inno Hub Content'
      }
    };
  }
};
</script>
