<template>
  <v-container>
    <confirm-dialog
      v-if="showConfirmDialog"
      :show="showConfirmDialog"
      :confirm-click="addItem"
      @close="closeConfirmDialog"
    >
      <v-row class="confirm-container">
        <div class="confirm-title">
          New page: {{ currentItem.fields.title }}
        </div>
        <div class="confirm-sub-title">
          If you confirm now, the page will be shown in the
          Admin Panel for all the companies.
        </div>
      </v-row>
    </confirm-dialog>
    <v-card class="pa-3">
      <v-card-title class="title">
        New {{ title }} page
      </v-card-title>
      <v-card-text>
        <content-details
          @cancel="redirect"
          @save="openConfirmDialog"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { InnoHubService, ConfirmDialog } from '@kickbox/common-admin';
import { TYPES } from '@kickbox/common-util/constants/inno-hub';
import ContentDetails from './ContentDetails';

export default {
  name: 'AddPage',
  components: {
    ContentDetails,
    ConfirmDialog
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showConfirmDialog: false,
      currentItem: null
    };
  },
  computed: {
    title() {
      return this.type === TYPES.BEST_PRACTICE ? 'best practice' : this.type;
    }
  },
  methods: {
    openConfirmDialog(item) {
      this.currentItem = item;
      if (item.fields.disabled) {
        this.addItem();
      } else {
        this.showConfirmDialog = true;
      }
    },
    closeConfirmDialog() {
      this.currentItem = null;
      this.showConfirmDialog = false;
    },
    async addItem() {
      const newItem = {
        fields: {
          ...this.currentItem.fields,
          type: this.type
        },
        imageProperty: this.currentItem.imageProperty
      };
      const savedItem = await InnoHubService
        .saveInnoHubContent(newItem.fields, newItem.imageProperty);
      this.$store.commit('addInnoHubContent', savedItem);
      this.$store.dispatch('showSnackBar', { text: `The item ${newItem.fields.title} was added.` });
      this.redirect();
    },
    redirect() {
      const name = this.type === TYPES.SERVICE ? 'InnoHubServices' : 'InnoHubBestPractice';
      this.$router.push({ name, params: { type: this.type } });
    }
  }
};
</script>

<style scoped>
.v-card__subtitle, .v-card__text {
  line-height: initial;
}
.confirm-title {
  font-size: 17px;
  margin-bottom: 1rem;
}
.confirm-sub-title {
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 0.8rem;
}
</style>
