<template>
  <div>
    <v-text-field
      v-model="airtable.id"
      label="Id"
      clearable
      placeholder="Enter a Airtable Id"
    />
    <v-text-field
      v-model="airtable.redbox"
      label="Redbox form"
      clearable
      placeholder="Enter a RedBox form url"
      class="pt-1"
    />
    <v-text-field
      v-model="airtable.bluebox"
      label="Bluebox form"
      clearable
      placeholder="Enter a BlueBox form url"
      class="pt-1"
    />
    <v-text-field
      v-model="airtable.goldbox"
      label="Goldbox form"
      clearable
      placeholder="Enter a GoldBox form url"
      class="pt-1"
    />
    <v-checkbox
      v-model="airtable.enabled"
      hide-details
      label="Show certification popup reminder for admins"
      color="primary"
      class="pt-0 mt-0"
    />
  </div>
</template>

<script>
export default {
  name: 'AirtableSettings',
  props: {
    airtableData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      airtable: null
    };
  },
  watch: {
    airtable() {
      this.$emit('changed', this.airtable);
    }
  },
  created() {
    this.airtable = { ...this.airtableData };
  }
};
</script>
