<template>
  <v-container>
    <app-card-logo
      :companies="companies"
      type="company"
      @buttonClick="goToCreateNewCompany"
    />
  </v-container>
</template>

<script>
import { CardsLogo } from '@kickbox/common-admin';

export default {
  name: 'BaseCompanyList',
  components: {
    appCardLogo: CardsLogo
  },
  props: {
    trial: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      companies: []
    };
  },
  async created() {
    await this.$store.dispatch('loadCompanies');
    const allCompanies = this.$store.getters.companies;
    this.companies = allCompanies.filter((c) => c.trial === this.trial);
  },
  methods: {
    goToCreateNewCompany() {
      this.$router.push({ name: 'CreateCompany' });
    }
  }
};
</script>
