<template>
  <div>
    <label class="subtitle-2 my-3 d-block">Chapter {{ chapter.chapterNumber }}</label>
    <v-text-field
      :value="chapter.id"
      label="Id"
      disabled
    />
    <v-text-field
      v-model="name"
      label="Name"
      placeholder="Enter name"
      clearable
      @input="update"
    />
    <v-text-field
      v-model="tagline"
      label="Tagline"
      placeholder="Enter tagline"
      clearable
      @input="update"
    />
    <app-button
      class="mb-4"
      secondary
      @click="edit"
    >
      Edit pages
    </app-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['chapter'],
  data() {
    return {
      name: '',
      tagline: ''
    };
  },
  computed: {
    ...mapGetters([
      'currentLanguage'
    ]),
    chapterContentLanguage() {
      return this.chapter.content[this.currentLanguage];
    },
  },
  watch: {
    currentLanguage() {
      this.getContent();
    }
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.name = this.chapterContentLanguage.name;
      this.tagline = this.chapterContentLanguage.tagline;
    },
    edit() {
      this.$emit('edit');
    },
    update() {
      const items = {
        name: this.name,
        tagline: this.tagline
      };
      this.$store.dispatch('updateChapter', { chapter: this.chapter, items });
    }
  }
};
</script>

<style scoped>

</style>
