import { TagService } from '@kickbox/common-admin';

const state = {
  tags: [],
  projectMap: {}
};

const getters = {
  tags: (st) => st.tags,
  projectMap: (st) => st.projectMap
};

const mutations = {
  setProjectMap(st, payload) {
    st.projectMap = payload;
  },
  setTags(st, payload) {
    st.tags = payload;
  },
  addTag(st, payload) {
    st.tags.push(payload);
  },
  removeTag(st, payload) {
    st.tags = st.tags.filter((tag) => tag.id !== payload[0].id);
  },
  updateTag(st, payload) {
    st.tags = st.tags.map((tag) => (tag.id === payload.id
      ? Object.assign(tag, payload) : tag));
  }
};

const actions = {
  async updateTag({ commit }, properties) {
    if (properties.id) {
      return commit('updateTag', await TagService.updateTag(properties));
    }
    return commit('addTag', await TagService.updateTag(properties));
  },
  async removeTag({ commit }, tagId) {
    commit('removeTag', await TagService.removeTag(tagId));
  },
  async getTags({ commit, dispatch }) {
    dispatch('setIsLoading', { tags: true });
    await commit('setTags', await TagService.getGlobalTags());
    await commit('setProjectMap', await TagService.getProjectTagCount());
    dispatch('setIsLoading', { tags: false });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
