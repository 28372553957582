<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="10"
        lg="8"
        xl="6"
      >
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                />
                <v-spacer />
                <download-excel
                  :data="searchResult"
                  :fields="exportDataFields"
                  name="download.xls"
                >
                  <app-button secondary>
                    Export Results
                  </app-button>
                </download-excel>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="emails"
                :search="search"
                :loading="isLoading.emails"
                :custom-filter="customFilter"
                :no-data-text="noData"
                class="table-container"
                :footer-props="{
                  itemsPerPageText: 'Emails per page:',
                  itemsPerPageOptions: [15, 30, 45, { text: 'All', value: -1 }]
                }"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import landingPageService from '@/services/landingPageService';

export default {
  data() {
    return {
      exportDataFields: {
        Email: 'email',
        'Creation Date': 'creationDate',
      },
      search: '',
      headers: [
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Creation Date', value: 'creationDate', align: 'left' },
      ],
      emails: [],
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
    ]),
    noData() {
      return this.isLoading.emails ? 'loading...' : 'No emails.';
    },
    searchResult() {
      return this.emails.filter((email) => this.customFilter(email.email, this.search, email));
    }
  },
  created() {
    this.$store.dispatch('setIsLoading', { email: true });
    landingPageService.getEmails()
      .then((emails) => {
        this.emails = emails.map((email) => ({
          email: email.get('email'),
          creationDate: moment(email.createdAt).format('MMM DD, YYYY'),
        }));
      })
      .finally(() => {
        this.$store.dispatch('setIsLoading', { email: false });
      });
  },
  methods: {
    customFilter(value, search, item) {
      const customSearch = search.toString().toLowerCase();
      return item.creationDate.toLowerCase().includes(customSearch)
        || item.email.toLowerCase().includes(customSearch);
    }
  }
};
</script>
