<template>
  <v-container>
    <v-row>
      <app-button
        class="ml-3 mb-3"
        @click="addPage"
      >
        Add page
      </app-button>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="items.length">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel
              v-for="(item, index) in items"
              :key="item.id"
            >
              <edit-content
                :item="item"
                :item-number="index + 1"
              />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { InnoHubService } from '@kickbox/common-admin';
import { TYPES } from '@kickbox/common-util/constants/inno-hub';
import { mapGetters } from 'vuex';
import EditContent from './EditContent';

export default {
  name: 'Content',
  components: {
    EditContent
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      panel: [],
      items: []
    };
  },
  computed: {
    ...mapGetters(['services', 'bestPractices'])
  },
  watch: {
    type() {
      this.initialize();
    }
  },
  async created() {
    const innoHubContent = await InnoHubService.getInnoHubContent(false);
    this.$store.commit('setInnoHubContent', innoHubContent);
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.type === TYPES.SERVICE) {
        this.items = this.services;
      } else if (this.type === TYPES.BEST_PRACTICE) {
        this.items = this.bestPractices;
      }
    },
    addPage() {
      this.$router.push({ name: 'InnoHubAddContent', params: { type: this.type } });
    }
  }
};
</script>
