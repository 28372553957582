<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>Edit content</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-3">
        <editor
          :show-cancel-button="false"
          :show-submit-button="false"
          :content="description"
          @keyUp="updateDescription"
        />
        <v-combobox
          :value="hashtags"
          label="Hashtags"
          chips
          multiple
          clearable
          class="mt-3"
          @change="updateHashTags"
        >
          <template
            slot="selection"
            slot-scope="data"
          >
            <v-chip
              :input-value="data.selected"
              close
              small
              @click:close="removeHashtag(data.item)"
            >
              {{ data.item }}
            </v-chip>
          </template>
        </v-combobox>
        <v-row
          v-for="(button, i) in buttons"
          :key="i"
        >
          <v-col>
            <v-text-field
              :value="button.title"
              :label="`Button ${i + 1}`"
              @input="updateButton(i, $event, 'title')"
            />
          </v-col>
          <v-col>
            <v-text-field
              :value="button.link"
              :label="`Button URL`"
              @input="updateButton(i, $event, 'link')"
            />
          </v-col>
        </v-row>
        <app-button
          right
          secondary
          @click="addButton"
        >
          Add button
        </app-button>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { Editor } from '@kickbox/common-admin';
import { mapGetters } from 'vuex';

export default {
  components: {
    Editor,
  },
  props: ['pageNumber'],
  data() {
    return {
      panel: false,
      hashtags: [],
      description: '',
      buttons: [],
      pageContentLanguage: null
    };
  },
  computed: {
    ...mapGetters([
      'currentLanguage',
      'pages'
    ]),
  },
  watch: {
    currentLanguage() {
      this.getContent();
    }
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.pageContentLanguage = this.pages
        .find((p) => p.pageNumber === this.pageNumber).content[this.currentLanguage];
      this.hashtags = this.pageContentLanguage.hashtags;
      this.description = this.pageContentLanguage.description;
      this.buttons = this.pageContentLanguage.buttons;
    },
    addButton() {
      this.$store.dispatch('addPageButton', {
        pageNumber: this.pageNumber,
        title: '',
        link: ''
      });
      this.getContent();
    },
    updateDescription(description) {
      this.$store.dispatch('updateDescription', { item: description, pageNumber: this.pageNumber });
    },
    updateButton(index, propData, prop) {
      const button = this.buttons[index];
      const newButtonData = {
        ...button,
        [prop]: propData
      };
      this.$store.dispatch('updatePageButton', {
        pageNumber: this.pageNumber,
        index,
        button: newButtonData
      });
    },
    updateHashTags(item) {
      this.$store.dispatch('updateHashTags', { item, pageNumber: this.pageNumber });
      this.getContent();
    },
    removeHashtag(item) {
      this.$store.dispatch('removeHashTag', { item, pageNumber: this.pageNumber });
      this.getContent();
    }
  }
};
</script>
