import Parse from '@kickbox/common-util/src/parse';
import store from '@/store';

export default {
  getBookChapters(type) {
    return Parse.Cloud.run('getBookChapters', { type });
  },
  getBookPages(chapterNumber, type) {
    return Parse.Cloud.run('getBookPages', { chapterNumber, type });
  },
  saveBookChapters(chapterArray, type) {
    return this.saveExtraMileObject({ className: 'BookChapter', objectArray: chapterArray, type });
  },
  saveBookPages(pageArray, chapterId) {
    return this.saveExtraMileObject({ className: 'BookPage', objectArray: pageArray, chapterId });
  },
  saveExtraMileObject(properties) {
    const { className, objectArray, type, chapterId } = properties;
    const language = store.getters.currentLanguage;
    return Parse.Cloud.run('saveExtraMileObject', { className, objectArray, language, type, chapterId });
  },
};
