import { TYPES } from '@kickbox/common-util/constants/inno-hub';

const state = {
  innoHubContent: [],
  services: [],
  bestPractices: []
};

const getters = {
  services: (st) => st.innoHubContent.filter((i) => i.type === TYPES.SERVICE),
  bestPractices: (st) => st.innoHubContent.filter((i) => i.type === TYPES.BEST_PRACTICE)
};

const mutations = {
  setInnoHubContent(st, payload) {
    st.innoHubContent = payload;
  },
  addInnoHubContent(st, payload) {
    st.innoHubContent.push(payload);
  },
  updateInnoHubContent(st, payload) {
    st.innoHubContent = st.innoHubContent
      .map((i) => (i.id === payload.id ? Object.assign(i, payload) : i));
  }
};

export default {
  state,
  getters,
  mutations
};
