<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pa-0">
      {{ label }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'CompanyExpansionPanel',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
};
</script>
