import Parse from '@kickbox/common-util/src/parse';

export default {
  async removeCompanyData(companyId, companyName) {
    return Parse.Cloud.run('removeCompanyData', {
      companyId,
      companyName
    });
  },
  async getCompanyRemoveData(companyId) {
    return Parse.Cloud.run('getCompanyRemoveData', { companyId });
  },
  async migrateCompanyData(companyId) {
    return Parse.Cloud.run('migrateCompanyData', { companyId });
  }
};
