import Parse from '@kickbox/common-util/src/parse';
import { KICKBOX_ROLES } from '@kickbox/common-util';
import store from '@/store';
import router from '@/router';

export default {
  async loginAdmin(loginEmail, password) {
    const email = loginEmail.toLowerCase();
    try {
      await Parse.User.logIn(email, password);

      const role = await new Parse.Query(Parse.Role)
        .equalTo('name', KICKBOX_ROLES.BLUELION_ADMIN)
        .first();
      const adminUser = await role.getUsers().query()
        .equalTo('username', email)
        .first();
      if (!adminUser) {
        Parse.User.logOut();
        store.dispatch('showSnackBar', { text: 'The account does not have Admin privileges' });
      } else {
        router.push({ name: 'Home' });
      }
    } catch (error) {
      Parse.User.logOut();
      // https://docs.parseplatform.org/rest/guide/#error-codes
      if (error.code === 100) {
        store.dispatch('showSnackBar', { text: 'Server connection lost.' });
      } else if (error.code === 101) {
        store.dispatch('showSnackBar', { text: 'The password is invalid.' });
      } else {
        store.dispatch('showSnackBar', { text: `Unexpected error: ${error.code}` });
      }
    }
  }
};
