<template>
  <v-row>
    <v-col
      cols="12"
      offset-sm="1"
      md="10"
      lg="7"
      xl="6"
    >
      <app-form-company :from-trial="fromTrial" />
    </v-col>
  </v-row>
</template>

<script>
import FormCompany from './FormCompany';

export default {
  components: {
    appFormCompany: FormCompany
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'CompaniesTrialManage') {
        vm.fromTrial = true;
      }
    });
  },
  data() {
    return {
      fromTrial: false
    };
  },
};
</script>
