<template>
  <v-card class="pt-4 pa-4">
    <confirm-dialog
      :show="showRemoveDialog"
      :confirm-click="removeCompanyData"
      :disabled="disabledConfirmButton"
      @close="showRemoveDialog = false"
    >
      <div class="mb-2">
        <span>Are you absolutely sure?</span>
      </div>
      <div class="body-1">
        <span>
          You are about to remove the data belonging to the company <b>{{ name }}</b>, including:
        </span>
        <ul class="my-2">
          <li>{{ companyData.users }} users</li>
          <li>{{ companyData.projects }} projects</li>
          <li>{{ companyData.campaigns }} campaigns</li>
        </ul>
        <p class="font-weight-regular">
          <b>This action cannot be undone</b>
          and you will be solely responsible for the loss of data.
        </p>
        <p>To confirm, type the company name in the text input field.</p>
        <v-text-field
          v-model="verifyCompanyName"
          label="Company Name"
          clearable
          solo
          placeholder="Enter the company name"
          class="mt-4"
        />
      </div>
    </confirm-dialog>
    <select-image
      ref="selectImage"
      :initial-image="company && company.logo"
      :width="220"
      :height="70"
      label="Company Logo"
      required
    />
    <v-text-field
      :value="id"
      label="Company Id"
      class="mt-4"
      disabled
    />
    <v-text-field
      ref="name"
      v-model="name"
      v-ruid="'name'"
      :rules="[() => !!name || 'This field is required']"
      label="Company Name*"
      clearable
      placeholder="Enter a company name"
      class="mt-4"
      required
    />
    <v-combobox
      ref="domain"
      v-model="domain"
      v-ruid="'domains'"
      :rules="[rules.atLeastOneItem, rules.uniqueDomain(domain, domainReference)]"
      label="Company Domains*"
      placeholder="Add or remove a domain"
      chips
      multiple
      clearable
      append-icon
      validate-on-blur
      required
      class="mt-4"
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        <v-chip
          :input-value="data.selected"
          close
          small
          @click:close="removeDomain(domain, data.item)"
        >
          {{ data.item }}
        </v-chip>
      </template>
    </v-combobox>
    <v-select
      v-model="features"
      v-ruid="'features'"
      :items="allFeatures"
      label="Available Features"
      multiple
      placeholder="Select"
      class="mt-4"
      item-text="name"
    />
    <v-select
      ref="enabledLanguages"
      v-model="enabledLanguages"
      v-ruid="'languages'"
      :rules="[rules.atLeastOneItem]"
      :items="allLanguages"
      label="Available Languages*"
      multiple
      placeholder="Select"
      class="mt-4"
      item-text="name"
      item-value="key"
      @change="checkDefaultLanguage"
    />
    <v-autocomplete
      ref="language"
      v-model="language"
      v-ruid="'defaultLanguage'"
      :rules="[rules.required]"
      :items="enabledLanguages"
      label="Default Language*"
      placeholder="Select"
      class="mt-4"
      item-text="name"
      item-value="key"
    />
    <v-expansion-panels flat>
      <company-expansion-panel label="SSO Settings">
        <sso-settings
          :sso-data="ssoData"
          @changed="ssoData = $event"
        />
      </company-expansion-panel>
      <company-expansion-panel label="Airtable Settings">
        <airtable-settings
          :airtable-data="airtableData"
          @changed="airtableData = $event"
        />
      </company-expansion-panel>
      <company-expansion-panel label="Onboarding Room Settings">
        <expansion-url-settings
          v-model="onboardingRoomData"
          placeholder="Enter an 'Onboarding Room' url"
        />
      </company-expansion-panel>
      <company-expansion-panel label="Knowledge Hub Settings">
        <expansion-url-settings
          v-model="knowledgeHubData"
          placeholder="Enter an 'Knowledge Hub' url"
        />
      </company-expansion-panel>
    </v-expansion-panels>
    <v-checkbox
      v-model="smartlook"
      hide-details
      label="Enable Smartlook"
      color="primary"
      class="mt-1"
    />
    <div class="mt-4">
      <label class="body-1">
        Please be sure that you have at least one admin and unit before enable the company
      </label>
      <v-checkbox
        v-model="trial"
        hide-details
        label="Trial company"
        color="primary"
        class="my-2"
      />
      <v-checkbox
        v-model="enabled"
        hide-details
        label="Enable company"
        color="primary"
        class="mt-1"
      />
      <v-checkbox
        v-model="migrated"
        hide-details
        label="Migrated"
        color="primary"
        class="mt-1"
      />
      <v-text-field
        v-model="whiteEmailMigration"
        label="Allow login email post Migration"
        class="mt-4"
        hint="The email address should belong to a company admin"
      />
    </div>
    <div class="my-2">
      <app-button
        :loading="loadingButton"
        class="mt-4"
        @click="manageCompany"
      >
        {{ buttonText }}
      </app-button>
      <app-button
        v-if="!trial"
        :loading="loadingButton"
        class="mt-4 ml-4"
        color="red"
        :disabled="disabledMigratedButton"
        secondary
        @click="migrateCompany"
      >
        Migrate company data
      </app-button>
      <app-button
        v-if="company && company.trial"
        :loading="loadingButton"
        class="mt-4 ml-4"
        color="red"
        secondary
        @click="getCompanyData"
      >
        Remove company data
      </app-button>
    </div>
    <label class="body-1">* Mandatory field</label>
  </v-card>
</template>

<script>
import slug from 'slug';
import {
  CompanyService, EntityService, ValidateMixin, SelectImage, ConfirmDialog
} from '@kickbox/common-admin';
import {
  removeItemFromCollection
} from '@kickbox/common-util/src/util/removeItemFromCollection';

import FEATURE_NAMES from '@kickbox/common-util/constants/feature-names';
import languageService from '@/services/languageService';
import SsoSettings from './SsoSettings';
import AirtableSettings from './AirtableSettings';
import ExpansionUrlSettings from './ExpansionUrlSettings';
import companyService from '@/services/companyService';
import CompanyExpansionPanel from '@/components/companies/CompanyExpansionPanel';

export default {
  components: {
    SelectImage,
    SsoSettings,
    AirtableSettings,
    ExpansionUrlSettings,
    ConfirmDialog,
    CompanyExpansionPanel
  },
  mixins: [ValidateMixin],
  props: {
    company: {
      type: Object,
      default: () => {
      }
    },
    fromTrial: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: '',
      name: null,
      domain: null,
      domainReference: null,
      buttonText: 'Create',
      allFeatures: [
        ...Object.values(FEATURE_NAMES)
      ],
      features: [],
      enabledLanguages: [],
      ssoData: {
        signed: false,
        usePost: false,
        passOnEmail: true,
        serviceIdentifier: ''
      },
      loadingButton: false,
      enabled: true,
      language: '',
      allLanguages: [],
      airtableData: {
        id: '',
        redbox: '',
        bluebox: '',
        goldbox: '',
        enabled: false
      },
      onboardingRoomData: {
        url: '',
        enabled: false
      },
      knowledgeHubData: {
        url: '',
        enabled: false
      },
      smartlook: false,
      trial: false,
      showRemoveDialog: false,
      companyData: {},
      verifyCompanyName: '',
      migrated: false,
      disabledMigratedButton: false,
      whiteEmailMigration: ''
    };
  },
  computed: {
    formMandatoryFields() {
      return {
        name: this.name,
        domain: this.domain,
        language: this.language
      };
    },
    formFields() {
      return {
        name: this.name,
        slug: slug(this.name, { lower: true }),
        domain: this.domain.map((d) => d.trim()),
        features: this.features,
        enabledLanguages: this.enabledLanguages,
        ssoData: this.ssoData,
        enabled: this.enabled,
        trial: this.trial,
        language: this.language,
        airtableData: this.airtableData,
        onboardingRoomData: this.onboardingRoomData,
        knowledgeHubData: this.knowledgeHubData,
        smartlook: this.smartlook,
        migrated: this.migrated,
        whiteEmailMigration: this.whiteEmailMigration
      };
    },
    defaultLanguages() {
      return this.allLanguages
        .filter((lang) => lang.defaultLanguage)
        .map((l) => l.key);
    },
    image() {
      return this.$refs.selectImage.image;
    },
    disabledConfirmButton() {
      return this.verifyCompanyName !== this.name;
    }
  },
  async created() {
    this.allLanguages = await languageService.getLanguages();
    if (this.company) {
      this.id = this.company.id;
      this.name = this.company.name;
      this.domain = [...this.company.domain];
      this.domainReference = [...this.company.domain];
      this.features = [...this.company.features];
      this.enabled = this.company.enabled;
      this.trial = this.company.trial;
      this.ssoData = this.company.ssoData;
      this.buttonText = 'Save';
      this.language = this.company.language;
      this.enabledLanguages = this.company.enabledLanguages;
      this.airtableData = this.company.airtableData;
      this.onboardingRoomData = { ...this.company.onboardingRoomData };
      this.knowledgeHubData = { ...this.company.knowledgeHubData };
      this.smartlook = this.company.smartlook;
      this.migrated = this.company.migrated;
      this.whiteEmailMigration = this.company.whiteEmailMigration;
    } else {
      this.enabledLanguages = this.defaultLanguages;
      this.features = [];
      this.trial = this.fromTrial;
    }
  },
  methods: {
    async isSsoDataValid() {
      if (this.features.includes(FEATURE_NAMES.SSO)) {
        try {
          const signed = this.ssoData.signed ? '/signed' : '';
          const usePost = this.ssoData.usePost ? '-post' : '';
          const response = this.ssoData.serviceIdentifier && await fetch(`${process.env.VUE_APP_PARSE_SERVER_URL}/../rest/saml/${this.ssoData.serviceIdentifier}/logininfo${usePost}${signed}`);
          return response && response.status === 200;
        } catch (error) {
          return false;
        }
      }

      return true;
    },
    async removeDomain(array, item) {
      removeItemFromCollection(array, item);
    },

    checkDefaultLanguage(languages) {
      // Check the language otherwise it the language change will trigger the validation
      if (this.language && !languages.includes(this.language)) {
        this.language = null;
      }
    },
    async manageCompany() {
      if (!this.image.hasImage()) {
        this.$store.dispatch('showSnackBar', { text: 'You need to add a company logo.' });
        return;
      }

      if (!(await this.isSsoDataValid())) {
        this.$store.dispatch('showSnackBar', { text: 'The input for SSO identifier isn\'t correct.' });
        return;
      }
      if (this.checkFields()) {
        this.loadingButton = true;
        if (this.buttonText === 'Create') {
          this.createCompany();
        } else {
          this.editCompany();
        }
        this.loadingButton = false;
      }
    },
    async createCompany() {
      const company = await CompanyService.createCompany(
        this.image, this.formFields
      );
      this.$store.commit('addCompany', company);
      this.$router.push({ name: 'EditCompany', params: { slug: company.slug, startingTab: 1 } });
    },
    async editCompany() {
      const entity = this.getValidatedEntityProperties(this.formFields);
      let imageProperty = null;
      if (!this.image.currentIsInitial) {
        imageProperty = {
          file: this.image,
          oldUrl: this.company.logo
        };
      }
      const company = await EntityService
        .updateEntity('Company', this.id, entity.setProperties, entity.unsetProperties, imageProperty);
      this.$store.commit('updateCompany', company);
      return company.get('trial') ? this.$router.push({ name: 'CompaniesTrialManage' }) : this.$router.push({ name: 'CompaniesManage' });
    },
    async removeCompanyData() {
      this.showRemoveDialog = false;
      await companyService.removeCompanyData(this.id, this.verifyCompanyName);
      this.$router.push({ name: 'CompaniesTrialManage' });
    },
    async getCompanyData() {
      this.companyData = await companyService.getCompanyRemoveData(this.id);
      this.showRemoveDialog = true;
    },
    async migrateCompany() {
      this.disabledMigratedButton = true;
      const migratedData = await companyService.migrateCompanyData(this.id);
      this.$store.dispatch('showSnackBar', { text: `The following data were migrated: ${migratedData.projects} projects and ${migratedData.users} users`, timeout: 3500 });
      this.disabledMigratedButton = false;
    }
  }
};
</script>

<style scoped>
.input-group {
  padding-top: 10px !important;
}

.tile-container {
  border-bottom: 1px solid #d0cbcb !important;
}

.list {
  padding: 0 !important;
}

.list__tile__action {
  min-width: 30px !important;
}

.list__tile__title {
  font-size: 14px !important;
}

.list .btn .btn__content .icon {
  color: #7b7676 !important;
}

label {
  color: rgba(0, 0, 0, .54);
  font-size: 12px;
}

.error-label {
  color: red;
}

.croppa-container {
  border: 1px solid grey;
}

.flex.xs5 {
  min-width: 100%;
}

.input-group .radio {
  padding-top: 0 !important;
}

</style>
