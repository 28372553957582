import { removeDuplicateOnArray } from '@kickbox/common-util';

const state = {
  defaultContent: []
};

const getters = {
  defaultContent: (st) => st.defaultContent
};

const mutations = {
  setDefaultContent(st, payload) {
    st.defaultContent = payload;
  },
  updateDefaultContent(st, payload) {
    st.defaultContent = st.defaultContent
      .map((dc) => (dc.name === payload.name && dc.language === payload.language
        ? Object.assign(dc, payload) : dc));
  },
  updateDefaultContents(st, payload) {
    st.defaultContent = removeDuplicateOnArray(st.defaultContent.concat(payload));
  }
};

export default {
  state,
  getters,
  mutations
};
