<template>
  <v-row>
    <v-col
      cols="12"
      lg="9"
      offset-sm="1"
      sm="10"
      xl="8"
    >
      <v-progress-linear
        v-if="loader"
        :indeterminate="loader"
        class="mb-6"
        height="2"
      />
      <template v-else>
        <v-tabs
          v-model="active"
          background-color="#ebebeb"
          centered
          grow
          show-arrows
          slider-color="primary"
        >
          <v-tab ruid="tab_general">
            General
          </v-tab>
          <v-tab ruid="tab_units">
            Units
          </v-tab>
          <v-tab ruid="tab_company_admins">
            Company Admins
          </v-tab>
          <v-tab
            v-if="company.features.includes(FEATURE_NAME.MULTI_ENTITY)"
            ruid="tab_unit_admins"
          >
            Unit Admins
          </v-tab>
          <v-tab ruid="tab_coaches">
            Coaches
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="active">
          <v-tab-item>
            <app-form-company :company="company" />
          </v-tab-item>
          <v-tab-item>
            <v-card class="pa-4">
              <app-form-units
                :company="company"
                @saved="goToCompanyList"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <app-admin-user-list
              :admin-user-emails="company.admins || []"
              :check-if-emails-exists="checkIfEmailsExists"
              :domains="company.domain"
              :is-loading="loading.admins"
              :list-item-actions="listItemActions.admins"
              :min-count="1"
              label="Company Admins"
              @save="save(ADMIN_ROLES.COMPANY_ADMINS, $event)"
            />
          </v-tab-item>
          <v-tab-item
            v-if="company.features.includes(FEATURE_NAME.MULTI_ENTITY)"
          >
            <app-admin-user-list
              :admin-user-emails="company.unitAdmins || []"
              :check-if-emails-exists="checkIfEmailsExists"
              :domains="company.domain"
              :is-loading="loading.unitAdmins"
              :list-item-actions="listItemActions.unitAdmins"
              label="Unit Admins"
              @save="save(ADMIN_ROLES.UNIT_ADMINS, $event)"
            />
          </v-tab-item>
          <v-tab-item>
            <app-coaching-tabs
              :check-if-emails-exists="checkIfEmailsExists"
              :coaches="coaches"
              :domains="company.domain"
              :list-item-actions="listItemActions.coaches"
              :loading="loading.coaches"
              class="coaching-tabs"
              @save="saveCoaches($event.type, $event.emailsToUpdate)"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { AdminUserList, AdminUsersMixin, CoachingTabs, FormUnits } from '@kickbox/common-admin';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import FormCompany from './FormCompany';

export default {
  components: {
    appFormCompany: FormCompany,
    appAdminUserList: AdminUserList,
    appFormUnits: FormUnits,
    appCoachingTabs: CoachingTabs
  },
  mixins: [AdminUsersMixin],
  props: {
    slug: {
      type: String,
      default: ''
    },
    startingTab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: this.startingTab,
      FEATURE_NAME,
      ADMIN_ROLES
    };
  },
  computed: {
    loader() {
      const { companies } = this.$store.getters.isLoading;
      return companies;
    },
    company() {
      return this.$store.getters.companyBySlug(this.slug);
    }
  },
  async created() {
    const areCompaniesLoaded = this.$store.getters.companies?.length;
    if (!areCompaniesLoaded) {
      await this.$store.dispatch('loadCompanies');
    }
  },
  methods: {
    goToCompanyList($event) {
      if ($event) {
        return this.$store.commit('updateCompany', $event);
      }
      return this.$router.push({ name: 'CompaniesManage' });
    },
    updateStore(adminUsers, adminUserType) {
      this.$store.commit('setAdminUsersByCompanySlug', {
        slug: this.slug,
        adminUsers,
        adminUserType
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$margin-top: 30px;
::v-deep .v-window__container {
  margin-top: $margin-top;
}

.coaching-tabs {
  margin-top: -$margin-top;
}
</style>
