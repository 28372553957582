<template>
  <div>
    <v-expansion-panel-header class="font-weight-medium">
      {{ itemNumber }} - {{ item.title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <content-details
        :item="item"
        @save="saveItem"
      />
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { InnoHubService } from '@kickbox/common-admin';
import ContentDetails from './ContentDetails';

export default {
  name: 'EditContent',
  components: {
    ContentDetails
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    itemNumber: {
      type: Number,
      default: 0
    }
  },
  methods: {
    async saveItem(item) {
      const savedItem = await InnoHubService.saveInnoHubContent(item.fields, item.imageProperty);
      this.$store.commit('updateInnoHubContent', savedItem);
      this.$store.dispatch('showSnackBar', { text: `The item ${item.fields.title} was saved.` });
    }
  }
};
</script>
