import Vue from 'vue';
import uniq from 'lodash/uniq';
import { CompanyService } from '@kickbox/common-admin';

const state = {
  companies: [],
  companiesDomains: []
};

const getters = {
  companies: (st) => st.companies,
  companiesDomains: (st) => st.companiesDomains,
  companyBySlug: (st) => (slug) => st.companies.find((company) => company.slug === slug)
};

const mutations = {
  setCompanies(st, payload) {
    st.companies = payload;
  },
  setCompaniesDomains(st, payload) {
    st.companiesDomains = payload;
  },
  setAdminUsersByCompanySlug(st, { slug, adminUsers, adminUserType }) {
    const index = st.companies.findIndex((company) => company.slug === slug);
    const company = { ...st.companies[index], [adminUserType]: adminUsers };
    Vue.set(st.companies, index, company);
  },
  setUnits(st, { units, slug }) {
    const index = st.companies.findIndex((company) => company.slug === slug);
    const company = { ...st.companies[index], units };
    Vue.set(st.companies, index, company);
  },
  addCompany(st, payload) {
    st.companies.push(payload);
  },
  updateCompany(st, payload) {
    st.companies = st.companies.map((company) => (company.id === payload.id
      ? { ...company, ...payload } : company));
  }
};

const actions = {
  async loadCompanies({ commit, dispatch }) {
    commit('setIsLoading', { companies: true });
    const allCompanies = await CompanyService.allCompanies();
    commit('setCompanies', allCompanies);
    const companiesDomains = uniq(allCompanies.map((company) => company.domain).flat());
    commit('setCompaniesDomains', companiesDomains);
    dispatch('setIsLoading', { companies: false });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
