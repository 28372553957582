<script>
import { HorizontalBar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;
export default {
  name: 'HorizontalBarChart',
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    return this.renderChart(this.chartData, this.options);
  }
};
</script>
